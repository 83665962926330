import {Injectable} from '@angular/core';
import { Observable, of } from 'rxjs';
import { LocalStorageService } from 'angular-web-storage';
import {Router} from '@angular/router';

import { switchMap } from 'rxjs/operators'; 
import {ContactUsformrule} from '../contactUs/contactUsformrule';  
import {Headers, Http} from '@angular/http';
import { AppConstants  } from './../AppConstants';


@Injectable()
export class ContactUsservice {

  private host = window.location.hostname;
  private headers = new Headers({
    'Content-Type': 'application/json',
    'projectid': AppConstants.projectId,
    'apikey': this.local.get('logedInUser').apiKey
   });
   commonUrl:string;
  constructor(private http: Http,private router: Router,private local: LocalStorageService) {    
   this.commonUrl = AppConstants.commonUrl;

  };

  /**
   * Return all users
   * @returns {Promise<User[]>}
   */
  getAll(): Promise<ContactUsformrule[]> {
     return this.http.get(this.commonUrl+'asset/getforuser?nameValuePair=true&sortAssetTypeNames=contactUs', {headers: this.headers})
      .toPromise()
      .then(response => {
        return response.json() as ContactUsformrule[];
      })
      .catch(this.handleError);
  }

  /**
   * Returns user based on id
   * @param id:string
   * @returns {Promise<User>}
   */
  get(id: string): Promise<ContactUsformrule> {
  
    return this.http.get(this.commonUrl+'asset/getforuser?nameValuePair=true&sortAssetIds='+id, {headers: this.headers})  
      .toPromise()
      .then(response => response.json() as ContactUsformrule)
      .catch(this.handleError);
  }

  /**
   * Adds new user
   * @param user:User
   * @returns {Promise<User>}
   */
  add(contactUsformrule: any): Promise<any>{
    return this.http.post(this.commonUrl + 'asset/add', JSON.stringify(contactUsformrule), {headers: this.headers})
      .toPromise()
      .then(response => response.json() as any)
      .catch(this.handleError)
  }

  /**
   * Updates user that matches to id
   * @param user:User
   * @returns {Promise<User>}
   */
  update(contactUsformrule: any): Promise<any>{
    return this.http.put(this.commonUrl + 'asset/update', JSON.stringify(contactUsformrule), {headers: this.headers})
      .toPromise()
      .then(response => response.json() as any)
      .catch(this.handleError)
  }

  /**
   * Removes user
   * @param id:string
   * @returns {Promise<ContactUsformrule>}
   */
  remove(id: string): Promise<any>{
    var data = JSON.stringify({_id: id,delete:1});
   return this.http.delete(this.commonUrl+'asset/delete/' + id + '?cascade=false', {headers: this.headers})
      .toPromise()
      .then(response => console.log(response))
      .catch(this.handleError)
  }

  /**
   * Handles error thrown during HTTP call
   * @param error:any
   * @returns {Promise<never>}
   */
  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }
}
