// Modules
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpModule} from '@angular/http';
import {AppRoutingModule} from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';


import { HashLocationStrategy, LocationStrategy } from '@angular/common';


// Components
import {AppComponent} from './app.component';
import { LoginComponent } from './login/login.component';

import {DashboardComponent} from './dashboard.component';


// Services

import { BannerUpdate } from './banner/bannerUpdate.component';import { CalculateHistoryUpdate } from './calculateHistory/calculateHistoryUpdate.component';import { ContactUsUpdate } from './contactUs/contactUsUpdate.component';import { ProductUpdate } from './product/productUpdate.component';import { PrintingDesignUpdate } from './printingDesign/printingDesignUpdate.component';import { SiteContentUpdate } from './siteContent/siteContentUpdate.component';
import { BannerAdd } from './banner/bannerAdd.component';import { CalculateHistoryAdd } from './calculateHistory/calculateHistoryAdd.component';import { ContactUsAdd } from './contactUs/contactUsAdd.component';import { ProductAdd } from './product/productAdd.component';import { PrintingDesignAdd } from './printingDesign/printingDesignAdd.component';import { SiteContentAdd } from './siteContent/siteContentAdd.component';
import { BannerDetail } from './banner/bannerDetail.component';import { CalculateHistoryDetail } from './calculateHistory/calculateHistoryDetail.component';import { ContactUsDetail } from './contactUs/contactUsDetail.component';import { ProductDetail } from './product/productDetail.component';import { PrintingDesignDetail } from './printingDesign/printingDesignDetail.component';import { SiteContentDetail } from './siteContent/siteContentDetail.component';

import { Banner} from './banner/banner.component';import { CalculateHistory} from './calculateHistory/calculateHistory.component';import { ContactUs} from './contactUs/contactUs.component';import { Product} from './product/product.component';import { PrintingDesign} from './printingDesign/printingDesign.component';import { SiteContent} from './siteContent/siteContent.component';
import { Bannerservice } from './services/bannerservice.service';import { CalculateHistoryservice } from './services/calculateHistoryservice.service';import { ContactUsservice } from './services/contactUsservice.service';import { Productservice } from './services/productservice.service';import { PrintingDesignservice } from './services/printingDesignservice.service';import { SiteContentservice } from './services/siteContentservice.service';
import { BannerserviceFileupload } from './services/bannerserviceFileupload.service';import { CalculateHistoryserviceFileupload } from './services/calculateHistoryserviceFileupload.service';import { ContactUsserviceFileupload } from './services/contactUsserviceFileupload.service';import { ProductserviceFileupload } from './services/productserviceFileupload.service';import { PrintingDesignserviceFileupload } from './services/printingDesignserviceFileupload.service';import { SiteContentserviceFileupload } from './services/siteContentserviceFileupload.service';

  

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
   
    DashboardComponent,
    
    
    BannerUpdate ,CalculateHistoryUpdate ,ContactUsUpdate ,ProductUpdate ,PrintingDesignUpdate ,SiteContentUpdate ,
    BannerAdd ,CalculateHistoryAdd ,ContactUsAdd ,ProductAdd ,PrintingDesignAdd ,SiteContentAdd ,
    BannerDetail ,CalculateHistoryDetail ,ContactUsDetail ,ProductDetail ,PrintingDesignDetail ,SiteContentDetail ,

    Banner,CalculateHistory,ContactUs,Product,PrintingDesign,SiteContent
   
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    AppRoutingModule,
    CKEditorModule,
    HttpClientModule
   

  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy},
  BannerserviceFileupload,CalculateHistoryserviceFileupload,ContactUsserviceFileupload,ProductserviceFileupload,PrintingDesignserviceFileupload,SiteContentserviceFileupload,

   Bannerservice ,CalculateHistoryservice ,ContactUsservice ,Productservice ,PrintingDesignservice ,SiteContentservice],
  bootstrap: [AppComponent]
})

export class AppModule {
}
