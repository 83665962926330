

import { Observable, of } from 'rxjs';

import { switchMap } from 'rxjs/operators';   

import {Component, OnInit} from '@angular/core';
import {SiteContentformrule} from './siteContentformrule';  
import {ActivatedRoute, Params} from '@angular/router';
import {Location} from '@angular/common';
import {SiteContentservice} from '../services/siteContentservice.service';

@Component({
  selector: 'siteContentDetail',
  templateUrl: './siteContentDetail.component.html',
  styleUrls: ['../app.component.css']
})

export class SiteContentDetail implements OnInit{
  siteContentformrule: SiteContentformrule;

  constructor(
    private siteContentservice: SiteContentservice,
    private route: ActivatedRoute,
    private location: Location
  ){};

  ngOnInit(): void{
    this.route.params
      .pipe(switchMap((params: Params) => this.siteContentservice.get(params['id'])))
      .subscribe(siteContentformrule => this.siteContentformrule = siteContentformrule);
  }

  goBack(): void{
    this.location.back();
  }
}
