

import { Observable, of } from 'rxjs';

import { switchMap } from 'rxjs/operators';   

import {Component, OnInit} from '@angular/core';
import {PrintingDesignformrule} from './printingDesignformrule';  
import {ActivatedRoute, Params} from '@angular/router';
import {Location} from '@angular/common';
import {PrintingDesignservice} from '../services/printingDesignservice.service';

@Component({
  selector: 'printingDesignDetail',
  templateUrl: './printingDesignDetail.component.html',
  styleUrls: ['../app.component.css']
})

export class PrintingDesignDetail implements OnInit{
  printingDesignformrule: PrintingDesignformrule;

  constructor(
    private printingDesignservice: PrintingDesignservice,
    private route: ActivatedRoute,
    private location: Location
  ){};

  ngOnInit(): void{
    this.route.params
      .pipe(switchMap((params: Params) => this.printingDesignservice.get(params['id'])))
      .subscribe(printingDesignformrule => this.printingDesignformrule = printingDesignformrule);
  }

  goBack(): void{
    this.location.back();
  }
}
