
import { Observable, of } from 'rxjs';

import { switchMap } from 'rxjs/operators';  
import {Component, OnInit} from '@angular/core';
import {PrintingDesignformrule} from './printingDesignformrule';  
import {ActivatedRoute, Params, Router} from '@angular/router';
import {PrintingDesignservice} from '../services/printingDesignservice.service';
import {PrintingDesignserviceFileupload} from "../services/printingDesignserviceFileupload.service";
import { LocalStorageService } from 'angular-web-storage';
import {Location} from "@angular/common";

import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';


@Component({
  selector: 'printingDesignUpdate',
  templateUrl: './printingDesignUpdate.component.html',
  styleUrls: ['../app.component.css']
})

export class PrintingDesignUpdate implements OnInit {
  dataForm: FormGroup;
  haserror = false;
  errors: string[];
  assetId: string;
  printingDesignformrule: PrintingDesignformrule;
    public Editor = ClassicEditor;

     public editorConfig = {
        fontFamily: {
          options: [
            'default',
            'Ubuntu, Arial, sans-serif',
            'Ubuntu Mono, Courier New, Courier, monospace'
          ]
        },
        toolbar: [
          'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' 
        ]
   };


  constructor(private printingDesignservice: PrintingDesignservice,
              private router: Router,
              private route: ActivatedRoute,
              private location: Location,
              private printingDesignserviceFileupload: PrintingDesignserviceFileupload,
              private formBuilder: FormBuilder,
              private local: LocalStorageService) {
  };

  ngOnInit(): void {
    this.route.params
      .pipe(switchMap((params: Params) => this.printingDesignservice.get(params['id'])))
      .subscribe(printingDesignformrule => {
      let response = JSON.parse(JSON.stringify(printingDesignformrule));
        this.printingDesignformrule = response.response[0].assetProperties;
        this.assetId = response.response[0].assetId;
        this.buildForm();
      });
  }

  buildForm(): void {
    this.dataForm = this.formBuilder.group({
      designName: [this.printingDesignformrule.designName, Validators.required],designImage: [, Validators.required],designImageAlt: [this.printingDesignformrule.designImageAlt, Validators.required],status: [this.printingDesignformrule.status, Validators.required],
    });
  }

  


  onSelecteddesignImage(event) {
							    if (event.target.files.length> 0) {
							      const designImage = event.target.files[0];
							      this.dataForm.get('designImage').setValue(designImage);
							    }
							  }

  onUpdate() {
    const formData = new FormData();
				    let printingDesign={"assetId":this.assetId,"assetName":this.dataForm.get('designName').value,"assetParentId":"0","assetPropertyList":[{"assetTypePropertyName":"designName","assetPropertyValue":this.dataForm.get('designName').value},{"assetTypePropertyName":"designImageAlt","assetPropertyValue":this.dataForm.get('designImageAlt').value},{"assetTypePropertyName":"status","assetPropertyValue":this.dataForm.get('status').value},],"assetTypeId":this.local.get("printingDesign")};  
				   
				   		var thisobj = this;
			            this.printingDesignservice.update(printingDesign)
			            .then(printingDesigndata => {
			              let response = JSON.parse(JSON.stringify(printingDesigndata));


			              	if (response.status == false) {
		                      this.errors.push(response.message);
		                    }
		                    else {

				    	let assetPropertyNames = '';

				    


    								if (thisobj.dataForm.get('designImage').value!='' && thisobj.dataForm.get('designImage').value!=null) {
    									assetPropertyNames+='assetPropertyNames=designImage&';
    									formData.append('files', thisobj.dataForm.get('designImage').value);
    								}
							

		            let printingDesignattr ='assetId=' + this.assetId + '&' + assetPropertyNames;

		            if (assetPropertyNames!='') {
				    	this.printingDesignserviceFileupload.add(printingDesignattr ,formData ).then(model =>{
	    	                if (model!=undefined) {	
		    	                let response = JSON.parse(JSON.stringify(model));

						        if (response.status==true) {
							    		 this.router.navigate(['/printingDesign']);
						          	
						        }
						    }
	                	});
	                }
               else {
              		if (response.status==true) {
						this.router.navigate(['/printingDesign']);
					}
              }
              
            }
        });

  }

  goBack(): void {
    this.location.back();
  }
}
