

import { Observable, of } from 'rxjs';

import { switchMap } from 'rxjs/operators';   

import {Component, OnInit} from '@angular/core';
import {Bannerformrule} from './bannerformrule';  
import {ActivatedRoute, Params} from '@angular/router';
import {Location} from '@angular/common';
import {Bannerservice} from '../services/bannerservice.service';

@Component({
  selector: 'bannerDetail',
  templateUrl: './bannerDetail.component.html',
  styleUrls: ['../app.component.css']
})

export class BannerDetail implements OnInit{
  bannerformrule: Bannerformrule;

  constructor(
    private bannerservice: Bannerservice,
    private route: ActivatedRoute,
    private location: Location
  ){};

  ngOnInit(): void{
    this.route.params
      .pipe(switchMap((params: Params) => this.bannerservice.get(params['id'])))
      .subscribe(bannerformrule => this.bannerformrule = bannerformrule);
  }

  goBack(): void{
    this.location.back();
  }
}
