
import { Observable, of } from 'rxjs';

import { switchMap } from 'rxjs/operators';  
import {Component, OnInit} from '@angular/core';
import {Productformrule} from './productformrule';  
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Productservice} from '../services/productservice.service';
import {ProductserviceFileupload} from "../services/productserviceFileupload.service";
import { LocalStorageService } from 'angular-web-storage';
import {Location} from "@angular/common";

import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';


@Component({
  selector: 'productUpdate',
  templateUrl: './productUpdate.component.html',
  styleUrls: ['../app.component.css']
})

export class ProductUpdate implements OnInit {
  dataForm: FormGroup;
  haserror = false;
  errors: string[];
  assetId: string;
  productformrule: Productformrule;
    public Editor = ClassicEditor;

     public editorConfig = {
        fontFamily: {
          options: [
            'default',
            'Ubuntu, Arial, sans-serif',
            'Ubuntu Mono, Courier New, Courier, monospace'
          ]
        },
        toolbar: [
          'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' 
        ]
   };


  constructor(private productservice: Productservice,
              private router: Router,
              private route: ActivatedRoute,
              private location: Location,
              private productserviceFileupload: ProductserviceFileupload,
              private formBuilder: FormBuilder,
              private local: LocalStorageService) {
  };

  ngOnInit(): void {
    this.route.params
      .pipe(switchMap((params: Params) => this.productservice.get(params['id'])))
      .subscribe(productformrule => {
      let response = JSON.parse(JSON.stringify(productformrule));
        this.productformrule = response.response[0].assetProperties;
        this.assetId = response.response[0].assetId;
        this.buildForm();
      });
  }

  buildForm(): void {
    this.dataForm = this.formBuilder.group({
      productTitle: [this.productformrule.productTitle, Validators.required],category: [this.productformrule.category, Validators.required],productImage: [, Validators.required],productImageAlt: [this.productformrule.productImageAlt, Validators.required],productPriceRange: [this.productformrule.productPriceRange, Validators.required],productShortDescription: [this.productformrule.productShortDescription, Validators.required],productLongDescription: [this.productformrule.productLongDescription, Validators.required],productAltImageName1: [this.productformrule.productAltImageName1, Validators.required],productAltImage1: [, Validators.required],productAltImageAlt1: [this.productformrule.productAltImageAlt1, Validators.required],productAltImageName2: [this.productformrule.productAltImageName2, Validators.required],productAltImage2: [, Validators.required],productAltImageAlt2: [this.productformrule.productAltImageAlt2, Validators.required],productAltImageName3: [this.productformrule.productAltImageName3, Validators.required],productAltImage3: [, Validators.required],productAltImageAlt3: [this.productformrule.productAltImageAlt3, Validators.required],productAltImageName4: [this.productformrule.productAltImageName4, Validators.required],productAltImage4: [, Validators.required],productAltImageAlt4: [this.productformrule.productAltImageAlt4, Validators.required],showOnHomePage: [this.productformrule.showOnHomePage, Validators.required],status: [this.productformrule.status, Validators.required],
    });
  }

  


  onSelectedproductImage(event) {
							    if (event.target.files.length> 0) {
							      const productImage = event.target.files[0];
							      this.dataForm.get('productImage').setValue(productImage);
							    }
							  }onSelectedproductAltImage1(event) {
							    if (event.target.files.length> 0) {
							      const productAltImage1 = event.target.files[0];
							      this.dataForm.get('productAltImage1').setValue(productAltImage1);
							    }
							  }onSelectedproductAltImage2(event) {
							    if (event.target.files.length> 0) {
							      const productAltImage2 = event.target.files[0];
							      this.dataForm.get('productAltImage2').setValue(productAltImage2);
							    }
							  }onSelectedproductAltImage3(event) {
							    if (event.target.files.length> 0) {
							      const productAltImage3 = event.target.files[0];
							      this.dataForm.get('productAltImage3').setValue(productAltImage3);
							    }
							  }onSelectedproductAltImage4(event) {
							    if (event.target.files.length> 0) {
							      const productAltImage4 = event.target.files[0];
							      this.dataForm.get('productAltImage4').setValue(productAltImage4);
							    }
							  }

  onUpdate() {
    const formData = new FormData();
				    let product={"assetId":this.assetId,"assetName":this.dataForm.get('productTitle').value,"assetParentId":"0","assetPropertyList":[{"assetTypePropertyName":"productTitle","assetPropertyValue":this.dataForm.get('productTitle').value},{"assetTypePropertyName":"category","assetPropertyValue":this.dataForm.get('category').value},{"assetTypePropertyName":"productImageAlt","assetPropertyValue":this.dataForm.get('productImageAlt').value},{"assetTypePropertyName":"productPriceRange","assetPropertyValue":this.dataForm.get('productPriceRange').value},{"assetTypePropertyName":"productShortDescription","assetPropertyValue":this.dataForm.get('productShortDescription').value},{"assetTypePropertyName":"productLongDescription","assetPropertyValue":this.dataForm.get('productLongDescription').value},{"assetTypePropertyName":"productAltImageName1","assetPropertyValue":this.dataForm.get('productAltImageName1').value},{"assetTypePropertyName":"productAltImageAlt1","assetPropertyValue":this.dataForm.get('productAltImageAlt1').value},{"assetTypePropertyName":"productAltImageName2","assetPropertyValue":this.dataForm.get('productAltImageName2').value},{"assetTypePropertyName":"productAltImageAlt2","assetPropertyValue":this.dataForm.get('productAltImageAlt2').value},{"assetTypePropertyName":"productAltImageName3","assetPropertyValue":this.dataForm.get('productAltImageName3').value},{"assetTypePropertyName":"productAltImageAlt3","assetPropertyValue":this.dataForm.get('productAltImageAlt3').value},{"assetTypePropertyName":"productAltImageName4","assetPropertyValue":this.dataForm.get('productAltImageName4').value},{"assetTypePropertyName":"productAltImageAlt4","assetPropertyValue":this.dataForm.get('productAltImageAlt4').value},{"assetTypePropertyName":"showOnHomePage","assetPropertyValue":this.dataForm.get('showOnHomePage').value},{"assetTypePropertyName":"status","assetPropertyValue":this.dataForm.get('status').value},],"assetTypeId":this.local.get("product")};  
				   
				   		var thisobj = this;
			            this.productservice.update(product)
			            .then(productdata => {
			              let response = JSON.parse(JSON.stringify(productdata));


			              	if (response.status == false) {
		                      this.errors.push(response.message);
		                    }
		                    else {

				    	let assetPropertyNames = '';

				    


    								if (thisobj.dataForm.get('productImage').value!='' && thisobj.dataForm.get('productImage').value!=null) {
    									assetPropertyNames+='assetPropertyNames=productImage&';
    									formData.append('files', thisobj.dataForm.get('productImage').value);
    								}
							


    								if (thisobj.dataForm.get('productAltImage1').value!='' && thisobj.dataForm.get('productAltImage1').value!=null) {
    									assetPropertyNames+='assetPropertyNames=productAltImage1&';
    									formData.append('files', thisobj.dataForm.get('productAltImage1').value);
    								}
							


    								if (thisobj.dataForm.get('productAltImage2').value!='' && thisobj.dataForm.get('productAltImage2').value!=null) {
    									assetPropertyNames+='assetPropertyNames=productAltImage2&';
    									formData.append('files', thisobj.dataForm.get('productAltImage2').value);
    								}
							


    								if (thisobj.dataForm.get('productAltImage3').value!='' && thisobj.dataForm.get('productAltImage3').value!=null) {
    									assetPropertyNames+='assetPropertyNames=productAltImage3&';
    									formData.append('files', thisobj.dataForm.get('productAltImage3').value);
    								}
							


    								if (thisobj.dataForm.get('productAltImage4').value!='' && thisobj.dataForm.get('productAltImage4').value!=null) {
    									assetPropertyNames+='assetPropertyNames=productAltImage4&';
    									formData.append('files', thisobj.dataForm.get('productAltImage4').value);
    								}
							

		            let productattr ='assetId=' + this.assetId + '&' + assetPropertyNames;

		            if (assetPropertyNames!='') {
				    	this.productserviceFileupload.add(productattr ,formData ).then(model =>{
	    	                if (model!=undefined) {	
		    	                let response = JSON.parse(JSON.stringify(model));

						        if (response.status==true) {
							    		 this.router.navigate(['/product']);
						          	
						        }
						    }
	                	});
	                }
               else {
              		if (response.status==true) {
						this.router.navigate(['/product']);
					}
              }
              
            }
        });

  }

  goBack(): void {
    this.location.back();
  }
}
