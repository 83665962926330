import {Component, OnInit} from '@angular/core';
import {Bannerformrule} from './bannerformrule';  
import {Bannerservice} from '../services/bannerservice.service';

import { LocalStorageService } from 'angular-web-storage';

import { AppConstants  } from './../AppConstants';

import {Router} from '@angular/router';

@Component({
  selector: 'Banner',
  templateUrl: './Banner.component.html',
  styleUrls: ['../app.component.css'],
  providers: [Bannerservice]
})

export class Banner implements OnInit {
    bannerformrule: Bannerformrule[];

   commonUrl:string;
   projectId:string;
  apiKey:string;

  constructor(private router: Router,
              private bannerservice: Bannerservice,
              private local: LocalStorageService) {
         this.commonUrl = AppConstants.commonUrl;
        this.projectId = AppConstants.projectId;
        this.apiKey = this.local.get('logedInUser').apiKey;

  }

  /**
   * Gets the existing users
   */
  getAll(): void {
    this.bannerservice.getAll()
      .then(bannerformrule => {
        console.log(bannerformrule);
        let response = JSON.parse(JSON.stringify(bannerformrule));
        this.bannerformrule = response.response;
      });
  }

  ngOnInit(): void {
    this.getAll();
  }

  update(id: string): void {
    this.router.navigate(['/bannerUpdate', id]);
  }

  remove(id: string): void {
    if(confirm("Are you sure to delete this")) {
      this.bannerservice.remove(id)
        .then(() => {
          this.getAll();
      });
    }
  }

  viewDetail(id: string): void {
    this.router.navigate(['/detail', id]);
  }


  
}
