
import { Observable, of } from 'rxjs';

import { switchMap } from 'rxjs/operators';  
import {Component, OnInit} from '@angular/core';
import {SiteContentformrule} from './siteContentformrule';  
import {ActivatedRoute, Params, Router} from '@angular/router';
import {SiteContentservice} from '../services/siteContentservice.service';
import {SiteContentserviceFileupload} from "../services/siteContentserviceFileupload.service";
import { LocalStorageService } from 'angular-web-storage';
import {Location} from "@angular/common";

import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';


@Component({
  selector: 'siteContentUpdate',
  templateUrl: './siteContentUpdate.component.html',
  styleUrls: ['../app.component.css']
})

export class SiteContentUpdate implements OnInit {
  dataForm: FormGroup;
  haserror = false;
  errors: string[];
  assetId: string;
  siteContentformrule: SiteContentformrule;
    public Editor = ClassicEditor;

     public editorConfig = {
        fontFamily: {
          options: [
            'default',
            'Ubuntu, Arial, sans-serif',
            'Ubuntu Mono, Courier New, Courier, monospace'
          ]
        },
        toolbar: [
          'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' 
        ]
   };


  constructor(private siteContentservice: SiteContentservice,
              private router: Router,
              private route: ActivatedRoute,
              private location: Location,
              private siteContentserviceFileupload: SiteContentserviceFileupload,
              private formBuilder: FormBuilder,
              private local: LocalStorageService) {
  };

  ngOnInit(): void {
    this.route.params
      .pipe(switchMap((params: Params) => this.siteContentservice.get(params['id'])))
      .subscribe(siteContentformrule => {
      let response = JSON.parse(JSON.stringify(siteContentformrule));
        this.siteContentformrule = response.response[0].assetProperties;
        this.assetId = response.response[0].assetId;
        this.buildForm();
      });
  }

  buildForm(): void {
    this.dataForm = this.formBuilder.group({
      logo: [, Validators.required],appVersion: [this.siteContentformrule.appVersion, Validators.required],paperPrice: [this.siteContentformrule.paperPrice, Validators.required],specialPaperPrice: [this.siteContentformrule.specialPaperPrice, Validators.required],pointPercentage: [this.siteContentformrule.pointPercentage, Validators.required],minVersion: [this.siteContentformrule.minVersion, Validators.required],status: [this.siteContentformrule.status, Validators.required],
    });
  }

  


  onSelectedlogo(event) {
							    if (event.target.files.length> 0) {
							      const logo = event.target.files[0];
							      this.dataForm.get('logo').setValue(logo);
							    }
							  }

  onUpdate() {
    const formData = new FormData();
				    let siteContent={"assetId":this.assetId,"assetName":this.dataForm.get('appVersion').value,"assetParentId":"0","assetPropertyList":[{"assetTypePropertyName":"appVersion","assetPropertyValue":this.dataForm.get('appVersion').value},{"assetTypePropertyName":"paperPrice","assetPropertyValue":this.dataForm.get('paperPrice').value},{"assetTypePropertyName":"specialPaperPrice","assetPropertyValue":this.dataForm.get('specialPaperPrice').value},{"assetTypePropertyName":"pointPercentage","assetPropertyValue":this.dataForm.get('pointPercentage').value},{"assetTypePropertyName":"minVersion","assetPropertyValue":this.dataForm.get('minVersion').value},{"assetTypePropertyName":"status","assetPropertyValue":this.dataForm.get('status').value},],"assetTypeId":this.local.get("siteContent")};  
				   
				   		var thisobj = this;
			            this.siteContentservice.update(siteContent)
			            .then(siteContentdata => {
			              let response = JSON.parse(JSON.stringify(siteContentdata));


			              	if (response.status == false) {
		                      this.errors.push(response.message);
		                    }
		                    else {

				    	let assetPropertyNames = '';

				    


    								if (thisobj.dataForm.get('logo').value!='' && thisobj.dataForm.get('logo').value!=null) {
    									assetPropertyNames+='assetPropertyNames=logo&';
    									formData.append('files', thisobj.dataForm.get('logo').value);
    								}
							

		            let siteContentattr ='assetId=' + this.assetId + '&' + assetPropertyNames;

		            if (assetPropertyNames!='') {
				    	this.siteContentserviceFileupload.add(siteContentattr ,formData ).then(model =>{
	    	                if (model!=undefined) {	
		    	                let response = JSON.parse(JSON.stringify(model));

						        if (response.status==true) {
							    		 this.router.navigate(['/siteContent']);
						          	
						        }
						    }
	                	});
	                }
               else {
              		if (response.status==true) {
						this.router.navigate(['/siteContent']);
					}
              }
              
            }
        });

  }

  goBack(): void {
    this.location.back();
  }
}
