import {NgModule}             from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {DashboardComponent}   from './dashboard.component';
import { LoginComponent } from './login/login.component';


import { BannerUpdate } from './banner/bannerUpdate.component';import { CalculateHistoryUpdate } from './calculateHistory/calculateHistoryUpdate.component';import { ContactUsUpdate } from './contactUs/contactUsUpdate.component';import { ProductUpdate } from './product/productUpdate.component';import { PrintingDesignUpdate } from './printingDesign/printingDesignUpdate.component';import { SiteContentUpdate } from './siteContent/siteContentUpdate.component';
import { BannerAdd } from './banner/bannerAdd.component';import { CalculateHistoryAdd } from './calculateHistory/calculateHistoryAdd.component';import { ContactUsAdd } from './contactUs/contactUsAdd.component';import { ProductAdd } from './product/productAdd.component';import { PrintingDesignAdd } from './printingDesign/printingDesignAdd.component';import { SiteContentAdd } from './siteContent/siteContentAdd.component';
import { Banner} from './banner/banner.component';import { CalculateHistory} from './calculateHistory/calculateHistory.component';import { ContactUs} from './contactUs/contactUs.component';import { Product} from './product/product.component';import { PrintingDesign} from './printingDesign/printingDesign.component';import { SiteContent} from './siteContent/siteContent.component';







const routes: Routes = [
  {path: '',component: LoginComponent},
  {path: 'login', component: LoginComponent},
  {path: 'dashboard', component: DashboardComponent},
  
  
  
  {path: 'bannerAdd', component: BannerAdd},{path: 'calculateHistoryAdd', component: CalculateHistoryAdd},{path: 'contactUsAdd', component: ContactUsAdd},{path: 'productAdd', component: ProductAdd},{path: 'printingDesignAdd', component: PrintingDesignAdd},{path: 'siteContentAdd', component: SiteContentAdd},
  {path: 'bannerUpdate/:id', component: BannerUpdate},{path: 'calculateHistoryUpdate/:id', component: CalculateHistoryUpdate},{path: 'contactUsUpdate/:id', component: ContactUsUpdate},{path: 'productUpdate/:id', component: ProductUpdate},{path: 'printingDesignUpdate/:id', component: PrintingDesignUpdate},{path: 'siteContentUpdate/:id', component: SiteContentUpdate},
  {path: 'banner', component: Banner},{path: 'calculateHistory', component: CalculateHistory},{path: 'contactUs', component: ContactUs},{path: 'product', component: Product},{path: 'printingDesign', component: PrintingDesign},{path: 'siteContent', component: SiteContent}
   
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
