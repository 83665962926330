import {Component, OnInit} from '@angular/core';
import {Productformrule} from './productformrule';  
import {Productservice} from '../services/productservice.service';

import { LocalStorageService } from 'angular-web-storage';

import { AppConstants  } from './../AppConstants';

import {Router} from '@angular/router';

@Component({
  selector: 'Product',
  templateUrl: './Product.component.html',
  styleUrls: ['../app.component.css'],
  providers: [Productservice]
})

export class Product implements OnInit {
    productformrule: Productformrule[];

   commonUrl:string;
   projectId:string;
  apiKey:string;

  constructor(private router: Router,
              private productservice: Productservice,
              private local: LocalStorageService) {
         this.commonUrl = AppConstants.commonUrl;
        this.projectId = AppConstants.projectId;
        this.apiKey = this.local.get('logedInUser').apiKey;

  }

  /**
   * Gets the existing users
   */
  getAll(): void {
    this.productservice.getAll()
      .then(productformrule => {
        console.log(productformrule);
        let response = JSON.parse(JSON.stringify(productformrule));
        this.productformrule = response.response;
      });
  }

  ngOnInit(): void {
    this.getAll();
  }

  update(id: string): void {
    this.router.navigate(['/productUpdate', id]);
  }

  remove(id: string): void {
    if(confirm("Are you sure to delete this")) {
      this.productservice.remove(id)
        .then(() => {
          this.getAll();
      });
    }
  }

  viewDetail(id: string): void {
    this.router.navigate(['/detail', id]);
  }


  

					  goToprintingDesign(assetId) {
								  			this.local.set('productcode',assetId);
								  			this.router.navigate(['/printingDesign']);
								  		}

					
}
