
import { Observable, of } from 'rxjs';

import { switchMap } from 'rxjs/operators';  
import {Component, OnInit} from '@angular/core';
import {ContactUsformrule} from './contactUsformrule';  
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ContactUsservice} from '../services/contactUsservice.service';
import {ContactUsserviceFileupload} from "../services/contactUsserviceFileupload.service";
import { LocalStorageService } from 'angular-web-storage';
import {Location} from "@angular/common";

import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';


@Component({
  selector: 'contactUsUpdate',
  templateUrl: './contactUsUpdate.component.html',
  styleUrls: ['../app.component.css']
})

export class ContactUsUpdate implements OnInit {
  dataForm: FormGroup;
  haserror = false;
  errors: string[];
  assetId: string;
  contactUsformrule: ContactUsformrule;
    public Editor = ClassicEditor;

     public editorConfig = {
        fontFamily: {
          options: [
            'default',
            'Ubuntu, Arial, sans-serif',
            'Ubuntu Mono, Courier New, Courier, monospace'
          ]
        },
        toolbar: [
          'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' 
        ]
   };


  constructor(private contactUsservice: ContactUsservice,
              private router: Router,
              private route: ActivatedRoute,
              private location: Location,
              private contactUsserviceFileupload: ContactUsserviceFileupload,
              private formBuilder: FormBuilder,
              private local: LocalStorageService) {
  };

  ngOnInit(): void {
    this.route.params
      .pipe(switchMap((params: Params) => this.contactUsservice.get(params['id'])))
      .subscribe(contactUsformrule => {
      let response = JSON.parse(JSON.stringify(contactUsformrule));
        this.contactUsformrule = response.response[0].assetProperties;
        this.assetId = response.response[0].assetId;
        this.buildForm();
      });
  }

  buildForm(): void {
    this.dataForm = this.formBuilder.group({
      name: [this.contactUsformrule.name, Validators.required],email: [this.contactUsformrule.email, Validators.required],phoneNo: [this.contactUsformrule.phoneNo, Validators.required],productType: [this.contactUsformrule.productType, Validators.required],message: [this.contactUsformrule.message, Validators.required],status: [this.contactUsformrule.status, Validators.required],
    });
  }

  


  

  onUpdate() {
    const formData = new FormData();
				    let contactUs={"assetId":this.assetId,"assetName":"this.dataForm.get('name').value","assetParentId":"0","assetPropertyList":[{"assetTypePropertyName":"name","assetPropertyValue":this.dataForm.get('name').value},{"assetTypePropertyName":"email","assetPropertyValue":this.dataForm.get('email').value},{"assetTypePropertyName":"phoneNo","assetPropertyValue":this.dataForm.get('phoneNo').value},{"assetTypePropertyName":"productType","assetPropertyValue":this.dataForm.get('productType').value},{"assetTypePropertyName":"message","assetPropertyValue":this.dataForm.get('message').value},{"assetTypePropertyName":"status","assetPropertyValue":this.dataForm.get('status').value},],"assetTypeId":this.local.get("contactUs")};  
				   
				    this.contactUsserviceFileupload.update(contactUs,formData ).then(model =>{
    	                if (model!=undefined) {	
	    	                let response = JSON.parse(JSON.stringify(model));

					        if (response.status==true) {
						    		 this.router.navigate(['/contactUs']);
					          	
					        }
					    }

				       
				    });

  }

  goBack(): void {
    this.location.back();
  }
}
