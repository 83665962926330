import {Component, OnInit} from '@angular/core';
import {CalculateHistoryformrule} from './calculateHistoryformrule';  
import {CalculateHistoryservice} from '../services/calculateHistoryservice.service';

import { LocalStorageService } from 'angular-web-storage';

import { AppConstants  } from './../AppConstants';

import {Router} from '@angular/router';

@Component({
  selector: 'CalculateHistory',
  templateUrl: './CalculateHistory.component.html',
  styleUrls: ['../app.component.css'],
  providers: [CalculateHistoryservice]
})

export class CalculateHistory implements OnInit {
    calculateHistoryformrule: CalculateHistoryformrule[];

   commonUrl:string;
   projectId:string;
  apiKey:string;

  constructor(private router: Router,
              private calculateHistoryservice: CalculateHistoryservice,
              private local: LocalStorageService) {
         this.commonUrl = AppConstants.commonUrl;
        this.projectId = AppConstants.projectId;
        this.apiKey = this.local.get('logedInUser').apiKey;

  }

  /**
   * Gets the existing users
   */
  getAll(): void {
    this.calculateHistoryservice.getAll()
      .then(calculateHistoryformrule => {
        console.log(calculateHistoryformrule);
        let response = JSON.parse(JSON.stringify(calculateHistoryformrule));
        this.calculateHistoryformrule = response.response;
      });
  }

  ngOnInit(): void {
    this.getAll();
  }

  update(id: string): void {
    this.router.navigate(['/calculateHistoryUpdate', id]);
  }

  remove(id: string): void {
    if(confirm("Are you sure to delete this")) {
      this.calculateHistoryservice.remove(id)
        .then(() => {
          this.getAll();
      });
    }
  }

  viewDetail(id: string): void {
    this.router.navigate(['/detail', id]);
  }


  
}
