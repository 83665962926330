

import { Observable, of } from 'rxjs';

import { switchMap } from 'rxjs/operators';   

import {Component, OnInit} from '@angular/core';
import {ContactUsformrule} from './contactUsformrule';  
import {ActivatedRoute, Params} from '@angular/router';
import {Location} from '@angular/common';
import {ContactUsservice} from '../services/contactUsservice.service';

@Component({
  selector: 'contactUsDetail',
  templateUrl: './contactUsDetail.component.html',
  styleUrls: ['../app.component.css']
})

export class ContactUsDetail implements OnInit{
  contactUsformrule: ContactUsformrule;

  constructor(
    private contactUsservice: ContactUsservice,
    private route: ActivatedRoute,
    private location: Location
  ){};

  ngOnInit(): void{
    this.route.params
      .pipe(switchMap((params: Params) => this.contactUsservice.get(params['id'])))
      .subscribe(contactUsformrule => this.contactUsformrule = contactUsformrule);
  }

  goBack(): void{
    this.location.back();
  }
}
