import {Component, OnInit} from '@angular/core';
import {SiteContentformrule} from './siteContentformrule';  
import {SiteContentservice} from '../services/siteContentservice.service';

import { LocalStorageService } from 'angular-web-storage';

import { AppConstants  } from './../AppConstants';

import {Router} from '@angular/router';

@Component({
  selector: 'SiteContent',
  templateUrl: './SiteContent.component.html',
  styleUrls: ['../app.component.css'],
  providers: [SiteContentservice]
})

export class SiteContent implements OnInit {
    siteContentformrule: SiteContentformrule[];

   commonUrl:string;
   projectId:string;
  apiKey:string;

  constructor(private router: Router,
              private siteContentservice: SiteContentservice,
              private local: LocalStorageService) {
         this.commonUrl = AppConstants.commonUrl;
        this.projectId = AppConstants.projectId;
        this.apiKey = this.local.get('logedInUser').apiKey;

  }

  /**
   * Gets the existing users
   */
  getAll(): void {
    this.siteContentservice.getAll()
      .then(siteContentformrule => {
        console.log(siteContentformrule);
        let response = JSON.parse(JSON.stringify(siteContentformrule));
        this.siteContentformrule = response.response;
      });
  }

  ngOnInit(): void {
    this.getAll();
  }

  update(id: string): void {
    this.router.navigate(['/siteContentUpdate', id]);
  }

  remove(id: string): void {
    if(confirm("Are you sure to delete this")) {
      this.siteContentservice.remove(id)
        .then(() => {
          this.getAll();
      });
    }
  }

  viewDetail(id: string): void {
    this.router.navigate(['/detail', id]);
  }


  
}
