

import { Observable, of } from 'rxjs';

import { switchMap } from 'rxjs/operators';   

import {Component, OnInit} from '@angular/core';
import {Productformrule} from './productformrule';  
import {ActivatedRoute, Params} from '@angular/router';
import {Location} from '@angular/common';
import {Productservice} from '../services/productservice.service';

@Component({
  selector: 'productDetail',
  templateUrl: './productDetail.component.html',
  styleUrls: ['../app.component.css']
})

export class ProductDetail implements OnInit{
  productformrule: Productformrule;

  constructor(
    private productservice: Productservice,
    private route: ActivatedRoute,
    private location: Location
  ){};

  ngOnInit(): void{
    this.route.params
      .pipe(switchMap((params: Params) => this.productservice.get(params['id'])))
      .subscribe(productformrule => this.productformrule = productformrule);
  }

  goBack(): void{
    this.location.back();
  }
}
