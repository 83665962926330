import { Injectable } from '@angular/core';


//import {HttpClient,HttpHeaders,HttpErrorResponse  } from '@angular/common/http';
import {Headers, Http} from '@angular/http';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import { LocalStorageService } from 'angular-web-storage';
import {Router} from '@angular/router';
import { catchError } from 'rxjs/operators'
import { throwError } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class AppService {

  constructor(private http: Http ,private router: Router,private local: LocalStorageService) { }

  userlogin(connectUserUrl,userloginData){

    return this.http.post(connectUserUrl + '/user/login', userloginData)
    .pipe(map((response: any) => response.json()))

  }


  getAssetTypesForUser(connectUrl,projectId){

    let headers = new Headers({
        'Content-Type': 'application/json',
        'projectid': projectId,
        'apikey': this.local.get('logedInUser').apiKey
    });

    return this.http.get(connectUrl+'assettype/getforuser', {headers: headers})
      .pipe(map((response: any) => response.json()));

  }


  userlogout(connectUserUrl,projectId){

    let headers = new Headers({
      'Content-Type': 'application/json',
      'projectId': projectId,
      'apikey': this.local.get('logedInUser').apiKey
    });

    return this.http.post(connectUserUrl+'/user/logout', null, {headers: headers})
      .pipe(map((response: any) => response.json()));

  }

  private handleError(error: any): Promise<any> {
    if(error.status==903){
      this.local.clear();
      this.router.navigate(['/login']);
    }else{
      alert("We Are Experiencing Some Problem, Kindly Try After Sometime !");
    }
    return Promise.reject(error.message || error);
  }

  private loginhandleError(error: any): Promise<any> {

    if(error.error.message) {
      alert(error.error.message);
    } else {
      alert("Please Check Your Internet Connection!");
    }

    return Promise.reject(error.message || error);
  }

}