import { Component, OnInit } from '@angular/core';
import {AppService} from '../app.service';
import { AppConstants  } from '../AppConstants';
import {Router} from '@angular/router';
import { FormBuilder, FormGroup ,FormControl,Validators} from '@angular/forms';
import { LocalStorageService } from 'angular-web-storage';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  userLoginForm: FormGroup;
  connectUserUrl:string;
  userloginData:any;
  errorshow:boolean=false;
  errormsg:string;
  
 
  constructor(private appService: AppService,private router: Router,private fb: FormBuilder,private local: LocalStorageService) {
    this.connectUserUrl = AppConstants.connectUserUrl;
   }
  
  ngOnInit() {


    if (this.local.get('logedInUser')!=null) window.location.href='/#/dashboard';
    this.userLoginForm = this.fb.group({
      username: ['', Validators.required], password: ['', Validators.required]
});
  }
  
  userLoginSubmit() {
    // stop here if form is invalid
    if (this.userLoginForm.invalid) {
      this.errormsg="User Id Or Password Is Invalid !"
      this.errorshow=true;
      return;
    }
    this.userloginData={
      "password": this.userLoginForm.get('password').value,
      "username": this.userLoginForm.get('username').value
    };
    this.appService.userlogin(this.connectUserUrl,this.userloginData ).subscribe(userLog =>{  
        let userLogRes = JSON.parse(JSON.stringify(userLog));
        // console.log(userLogRes)
          this.local.set('logedInUser', userLogRes.response);
          this.getAssetTypeForUser();

    });
  
 }



    getAssetTypeForUser() {
      let thisobj = this;
      this.appService.getAssetTypesForUser(AppConstants.commonUrl,AppConstants.projectId ).subscribe(assetType =>{  
          let assetTypeRes = JSON.parse(JSON.stringify(assetType));
          // console.log(userLogRes)
          if (assetTypeRes.status==true) {
            assetTypeRes.response.forEach(function (eachAssetType) {
              thisobj.local.set(eachAssetType.assetTypeName, eachAssetType.assetTypeId);
            }); 
            //this.router.navigate(['/dashboard']);

            window.location.href='/';
          }

      });
    }
}
