import { Component } from '@angular/core';
import {AppService} from './app.service';
import { AppConstants  } from './AppConstants';
import { LocalStorageService } from 'angular-web-storage';
import {ActivatedRoute, Params, Router,NavigationStart } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'Demo App';
  connectUserUrl:string;
  projectId:string;
  apiKey:string = "";
  productcode:string = "";
			  

  constructor(private appService: AppService,private router: Router,private local: LocalStorageService) {
    this.connectUserUrl = AppConstants.connectUserUrl;
    this.apiKey = (this.local.get('logedInUser')!=null?this.local.get('logedInUser').apiKey:"");
    this.productcode = (this.local.get('productcode')!=null?this.local.get('productcode'):"");
			  
    this.projectId = AppConstants.projectId;
  }

  logout(){
    if(confirm("Are you sure want to logout?")) {
      this.appService.userlogout(this.connectUserUrl,this.projectId).subscribe(resp =>{
        let logoutresp=JSON.parse(JSON.stringify(resp));
        this.local.clear();
        //this.router.navigate(['/']);
        
        window.location.href='/';
      });
    }
  }
}