import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType} from '@angular/common/http';
import {Headers, Http} from '@angular/http';
import { switchMap } from 'rxjs/operators'; 
import { LocalStorageService } from 'angular-web-storage';

import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppConstants  } from './../AppConstants';


@Injectable()

export class ContactUsserviceFileupload{
  private host = window.location.hostname;
  private headers = new Headers({
    'projectid': AppConstants.projectId,
    'apikey': this.local.get('logedInUser').apiKey
   });
     commonUrl:string;

  constructor(private http: Http,private local: LocalStorageService) {

     this.commonUrl = AppConstants.commonUrl;

   }

  


				    add(contactUsformruleattr: any, formData ): Promise<any>{
				    	return this.http.post(`${this.commonUrl}` + 'asset/fileupload?'+contactUsformruleattr, formData, {headers: this.headers})
						      .toPromise()
						      .then(response => response.json() as any)
						      .catch(this.handleError)
				  }

  /**
   * Updates object that matches to id
   * @param object:Class
   * @returns {Promise<any>}
   */


  


				    update(contactUsformruleattr: any, formData): Promise<any>{
					    return this.http.post(`${this.commonUrl}` + 'asset/fileupload?'+contactUsformruleattr, formData, {headers: this.headers})
					      .toPromise()
					      .then(response => response.json() as any)
					      .catch(this.handleError)
				  }

  private getEventMessage(event: HttpEvent<any> ,formData) {

    switch (event.type) {

      case HttpEventType.UploadProgress:
        return this.fileUploadProgress(event);

      case HttpEventType.Response:
        return this.apiResponse(event);

      default:
      
    }
  }

  private fileUploadProgress(event) {
    const percentDone = Math.round(100 * event.loaded / event.total);
    return { status: 'progress', message: percentDone };
  }

  private apiResponse(event) {
    return event.body;
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }

}