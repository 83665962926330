import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import {BannerserviceFileupload} from "../services/bannerserviceFileupload.service";
import {Bannerservice} from "../services/bannerservice.service";
import {Router} from '@angular/router';
import {Location} from "@angular/common";
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { LocalStorageService } from 'angular-web-storage';




@Component({
  selector: 'bannerAdd',
  templateUrl: './bannerAdd.component.html',
  styleUrls: ['../app.component.css']
})
export class BannerAdd implements OnInit {
  

  dataForm: FormGroup;
  haserror = false;
  errors: string[];
      submittedDataForm = false;

  public Editor = ClassicEditor;
  public editorConfig = {
        fontFamily: {
          options: [
            'default',
            'Ubuntu, Arial, sans-serif',
            'Ubuntu Mono, Courier New, Courier, monospace'
          ]
        },
        toolbar: [
          'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' 
        ]
   };


  

  constructor(private fb: FormBuilder, 
    private bannerserviceFileupload: BannerserviceFileupload,
    private bannerservice: Bannerservice,
    private location: Location,

    private router: Router,private local: LocalStorageService) { }

  ngOnInit() {

    this.dataForm = this.fb.group({
      bannerName: [,Validators.required], bannerImage: [,Validators.required], imageAlt: [], orderBy: [], status: [1,Validators.required],
    });

  } 
  goBack(): void {
    this.location.back();
  }
   


  onSelectedbannerImage(event) {
							    if (event.target.files.length> 0) {
							      const bannerImage = event.target.files[0];
							      this.dataForm.get('bannerImage').setValue(bannerImage);
							    }
							  }
  get validate() { return this.dataForm.controls; }

  onSubmit() {
    
   this.submittedDataForm = true;

    // stop here if form is invalid
    if (this.dataForm.invalid) {
        return;
    }
    const formData = new FormData();
				    let banner={"assetName":this.dataForm.get('bannerName').value,"assetParentId":"0","assetPropertyList":[{"assetTypePropertyName":"bannerName","assetPropertyValue":this.dataForm.get('bannerName').value},{"assetTypePropertyName":"bannerImage","assetPropertyValue":"IGNORE"},{"assetTypePropertyName":"imageAlt","assetPropertyValue":this.dataForm.get('imageAlt').value},{"assetTypePropertyName":"orderBy","assetPropertyValue":this.dataForm.get('orderBy').value},{"assetTypePropertyName":"status","assetPropertyValue":this.dataForm.get('status').value}],"assetTypeId":this.local.get("banner")};  


				   	var thisobj = this;

		            this.bannerservice.add(banner)
		            .then(bannerdata => {
		              let response = JSON.parse(JSON.stringify(bannerdata));


			        if (response.status == false) {
                      this.errors.push(response.message);
                    }
                    else {
				   
				    	let assetPropertyNames = '';

				    


    								if (thisobj.dataForm.get('bannerImage').value!='' && thisobj.dataForm.get('bannerImage').value!=null) {
    									assetPropertyNames+='assetPropertyNames=bannerImage&';
    									formData.append('files', thisobj.dataForm.get('bannerImage').value);
    								}
							

		            let bannerattr ='assetId=' + response.response + '&' + assetPropertyNames;

		            if (assetPropertyNames!='') {

					    this.bannerserviceFileupload.add(bannerattr ,formData ).then(model =>{
					   
						    if (model!=undefined) {	
		    	                let response = JSON.parse(JSON.stringify(model));

						        if (response.status==true) {
							    		 this.router.navigate(['/banner']);
						          	
						        }
						    }
					    });
					}
                }
            });
    

  }

}