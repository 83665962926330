import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./app.component.css']
})

export class DashboardComponent implements OnInit{
  totalUsers: number;

  constructor(){};

  ngOnInit():void{
   

  }
}
